import React, { useState, useEffect } from 'react';
import ModificaAssociato from './ModificaAssociato.js';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'
import NuovoPagamento from './NuovoPagamento.js';
import { adalApiFetch } from './adalConfig';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

moment.locale('it-IT');

const dateFormatter = (params) => {
  return params ? moment(params).format("DD/MM/YYYY") : '';
};

const colonneVisite = [
  { field: 'data', headerName: 'Data Visita', flex: 1, valueFormatter: dateFormatter },
  { field: 'tipo', headerName: 'Tipo', flex: 1 },
  { field: 'stato', headerName: 'Stato', flex: 1 },
  { field: 'controllo', headerName: 'Controllo', flex: 1, valueFormatter: dateFormatter },
  { field: 'note', headerName: 'Note', flex: 2 },
];

const colonnePagamenti = [
  { field: 'data', headerName: 'Data Pagamento', flex: 1, valueFormatter: dateFormatter },
  { field: 'modalita', headerName: 'Modalità', flex: 1 },
  { field: 'importo', headerName: 'Importo', flex: 1 },
  { field: 'num_ricevuta', headerName: 'Numero ricevuta', flex: 1 },
];

  const VisiteAssociato = ({ idassociato }) => {
    const [visite, setVisite] = useState([]);
  
    const fetchData = (idassociato) => {
      adalApiFetch(fetch, `${process.env.REACT_APP_BASE_SERVER_URI}/visits/visiteassociato?idassociato=${idassociato}`)
        .then(result => result.json())
        .then(listaVisite => {
          setVisite(Object.values(listaVisite));
        });
    };
  
    useEffect(() => {
      fetchData(idassociato);
    }, [idassociato]);
  
    return (
      <DataGrid
        rows={visite}
        columns={colonneVisite}
        autoPageSize
      />
    );
  };
  
  const PagamentiAssociato = ({ idassociato }) => {
    const [pagamenti, setPagamenti] = useState([]);
  
    const fetchData = (idassociato) => {
      adalApiFetch(fetch, `${process.env.REACT_APP_BASE_SERVER_URI}/pagamentiassociato?idassociato=${idassociato}`)
        .then(result => result.json())
        .then(listaPagamenti => {
          setPagamenti(Object.values(listaPagamenti));
        });
    };
  
    useEffect(() => {
      fetchData(idassociato);
    }, [idassociato]);
  
    return (
      <DataGrid
        rows={pagamenti}
        columns={colonnePagamenti}
        autoPageSize
      />
    );
  };
  
const SchedaAssociato = () => {
  const { id } = useParams();
  const [associato, setAssociato] = useState(null);

  const fetchData = (idassociato) => {
    adalApiFetch(fetch, `${process.env.REACT_APP_BASE_SERVER_URI}/associato?idassociato=${idassociato}`)
      .then(result => result.json())
      .then(associato => {
        setAssociato(associato);
      });
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  let form_na = ' ';
  let form_np = ' ';

  if (associato) {
    form_na = (
      <ModificaAssociato
        idassociato={id}
        nome={associato.nome}
        cognome={associato.cognome}
        email={associato.email}
        note={associato.note}
        cap={associato.cap}
        luogo_nascita={associato.luogo_nascita}
        indirizzo={associato.indirizzo}
        codice_fiscale={associato.codice_fiscale}
        data_nascita={associato.data_nascita ? moment(associato.data_nascita).format('YYYY-MM-DD') : null}
        telefono={associato.telefono}
        data_scadenza={moment(associato.data_scadenza).format('YYYY-MM-DD')}
        data_iscrizione={moment(associato.data_iscrizione).format('YYYY-MM-DD')}
      />
    );
    form_np = (
      <NuovoPagamento
        idassociato={id}
        data_scadenza={moment(associato.data_scadenza).format('YYYY-MM-DD')}
      />
    );
  }

  return (
    <Container fluid="true">
      <Row>
        <Col sm="12" md="6">
          <Row>{form_na}</Row>
        </Col>
        <Col sm="12" md="6">
          <Row>{form_np}</Row>
          <Row><p>{' '}</p></Row>
          <Row style={{ height: '240px', overflowY: 'auto' }}><VisiteAssociato idassociato={id} /></Row>
          <Row><p>{' '}</p></Row>
          <Row style={{ height: '240px', overflowY: 'auto' }}><PagamentiAssociato idassociato={id} /></Row>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <NavLink to="/associati" className='mt-3'>
            <Button color="danger">Indietro</Button>
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
};

export default SchedaAssociato;