import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: 'ilsorrisodiisa.onmicrosoft.com',
  clientId: 'a463960d-d1aa-470f-98d7-31445866797a',
  endpoints: {
    api: 'a463960d-d1aa-470f-98d7-31445866797a',
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);