import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { NavLink, useParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'reactstrap';
import { adalApiFetch } from './adalConfig';
import './comunicazioni.css';
import moment from 'moment';

moment.locale('it-IT');

const dateFormatter = (date) => moment(date).format("DD/MM/YYYY");

const datetimeFormatter = (date) => moment(date).format("DD/MM/YYYY HH:mm");

const columns = [
  { field: 'id', headerName: '#', hide: true },
  {
    field: 'timestamp',
    headerName: 'Data e ora',
    width: 150,
    valueFormatter: (value) => datetimeFormatter(value),
  },
  { field: 'evento', headerName: 'Evento', width: 150 },
  { field: 'dettaglio', headerName: 'Dettaglio', width: 300 },
];

const TrackingComunicazione = ({ idcomunicazione }) => {
  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    adalApiFetch(fetch, `${process.env.REACT_APP_BASE_SERVER_URI}/trackingcomunicazione?idcomunicazione=${idcomunicazione}`)
      .then((result) => result.json())
      .then((tracking) => {
        const result = tracking.map((item) => ({
          id: item.id_tracking,
          evento: item.evento,
          timestamp: new Date(item.timestamp * 1000),
          dettaglio: item.dettaglio,
        }));
        setTracking(result);
        setLoading(false);
      });
  };

  return (
    <Container>
      <DataGrid
        rows={tracking}
        columns={columns}
        pageSize={10}
        autoHeight
        loading={loading}
      />
    </Container>
  );
};

const DettaglioComunicazione = () => {
  const { id } = useParams();
  const [comunicazione, setComunicazione] = useState({});

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const fetchData = (idcomunicazione) => {
    adalApiFetch(fetch, `${process.env.REACT_APP_BASE_SERVER_URI}/dettagliocomunicazione?idcomunicazione=${idcomunicazione}`)
      .then((result) => result.json())
      .then((comunicazione) => {
        setComunicazione(comunicazione[0]);
      });
  };

  const c = comunicazione;

  return (
    <Container>
      <Row className="p-3">
        <Table bordered>
          <tbody>
            <tr>
              <th scope="row">Destinatario</th>
              <td>{(c.nome && c.cognome) ? `${c.nome} ${c.cognome}` : '-'}</td>
              <th scope="row">Recapito</th>
              <td>{c.recapito}</td>
            </tr>
            <tr>
              <th scope="row">Scadenza</th>
              <td>{c.data_scadenza ? dateFormatter(c.data_scadenza) : '-'}</td>
              <th scope="row">Num. ricevuta</th>
              <td>{c.num_ricevuta}</td>
            </tr>
            <tr>
              <th scope="row">Tipo comunicazione</th>
              <td>{c.descrizione}</td>
              <th scope="row">Canale</th>
              <td>{c.canale}</td>
            </tr>
            <tr>
              <th scope="row">Data generazione</th>
              <td>{datetimeFormatter(c.data_generazione)}</td>
              <th scope="row">Data autorizzazione</th>
              <td>{datetimeFormatter(c.data_autorizzazione)}</td>
            </tr>
            <tr>
              <th scope="row">Data invio</th>
              <td>{datetimeFormatter(c.data_invio)}</td>
              <th scope="row">Esito invio</th>
              <td>{c.esito_invio}</td>
            </tr>
            <tr>
              <th scope="row">Testo</th>
              <td colSpan="3" className="pl-3">
                <span dangerouslySetInnerHTML={{ __html: c.testo }}></span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <Row>
        <Col>
          <TrackingComunicazione idcomunicazione={id} />
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <NavLink to="/archiviocomunicazioni">Comunicazioni inviate</NavLink>
        </Col>
        <Col md="10">
          <NavLink to="/comunicazioni">Comunicazioni</NavLink>
        </Col>
      </Row>
    </Container>
  );
};

export default DettaglioComunicazione;