import React from 'react'
import { Button, Badge, Navbar, Nav, Collapse, NavItem, NavbarBrand, NavbarToggler, NavLink, DropdownMenu, UncontrolledDropdown, DropdownItem, DropdownToggle} from 'reactstrap'
import { Link } from 'react-router-dom'
import isilogo from './images/ISI_LOGO_2.jpg';
import { adalApiFetch } from './adalConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
class Header extends React.Component {
	constructor(props) {
			super(props);
	    this.toggle = this.toggle.bind(this);

			this.state = {
				activeLink: false,
				isOpen: false,
				utente: {nome: '', cognome:''},
	    };
	}

	componentDidMount(){
		this.fetchData();
	}

	fetchData = () => {
    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/utente')
    .then(result=>{return result.json()})
    .then(u => {
      this.setState({utente: u});
		});
	}

	toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
	}
	
	render() {
		let breq, bcom;
		if(this.props.nreq > 0){
			breq =  <Link to="/richiesteiscrizione"><Button color="info" outline>Richieste{' '}<Badge>{this.props.nreq}</Badge></Button></Link>;
		}else{
			breq = ' ';
		}

		if(this.props.ncom > 0){
			bcom = <Link to="/comunicazioni"><Button color="info" outline>Comunicazioni{' '}<Badge>{this.props.ncom}</Badge></Button></Link>;
		}else{
			bcom = ' ';
		}

	  return <div>
			<Navbar color="faded" light expand="md">
      <NavbarBrand href="/"><img className="isilogo" src={isilogo} width="120" alt="Logo Il sorriso di Isa"/></NavbarBrand>
			<div>
				{breq}
				{' '}
				{bcom}
			</div>
			<NavbarToggler onClick={this.toggle} className="mr-2" />
			<Collapse isOpen={this.state.isOpen} navbar pills="true">
				<Nav className="ml-auto" navbar>
				<NavItem active={window.location.pathname === "/visite"}><NavLink href="/visite">Visite</NavLink></NavItem>
				<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret>
									Associati
								</DropdownToggle>
								<DropdownMenu end>
									<DropdownItem active={window.location.pathname === "/associati"}>
										<NavLink href="/associati">Elenco associati</NavLink>
									</DropdownItem>
									<DropdownItem active={window.location.pathname === "/nuovoassociato"}>
										<NavLink href="/nuovoassociato">Nuovo associato</NavLink>
									</DropdownItem>
									{
									<DropdownItem active={window.location.pathname === "/richiesteiscrizione"}>
										<NavLink href="/richiesteiscrizione">Richieste iscrizione</NavLink>
									</DropdownItem>
									}
								</DropdownMenu>
				</UncontrolledDropdown>	  	
				<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret>
									Comunicazioni
								</DropdownToggle>
								<DropdownMenu end>
									<DropdownItem active={window.location.pathname === "/comunicazioni"}>
										<NavLink href="/comunicazioni">Da approvare</NavLink>
									</DropdownItem>
									<DropdownItem active={window.location.pathname === "/archiviocomunicazioni"}>
										<NavLink href="/archiviocomunicazioni">Inviate</NavLink>
									</DropdownItem>
									<DropdownItem active={window.location.pathname === "/cestinocomunicazioni"}>
										<NavLink href="/cestinocomunicazioni">Cestino</NavLink>
									</DropdownItem>
								</DropdownMenu>
				</UncontrolledDropdown>	  	
				<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret>
									Profilo
								</DropdownToggle>
								<DropdownMenu end>
									<DropdownItem>
										<NavLink>{this.state.utente.nome+' '+this.state.utente.cognome}</NavLink>
									</DropdownItem>
									<DropdownItem>
										<NavLink>{this.state.utente.uid}</NavLink>
									</DropdownItem>
								</DropdownMenu>
				</UncontrolledDropdown>	  	
				</Nav>
				</Collapse>
		  	</Navbar>
		</div>
  }
}
export default Header