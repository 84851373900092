import React, { useEffect }  from 'react';
import { NavLink } from 'react-router-dom'
import { Alert, FormFeedback, Container, Col, Button, FormGroup, Label, Input} from 'reactstrap';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { adalApiFetch } from './adalConfig';

// Max associated birthday
var MAXBIRTHDAY = new Date();
MAXBIRTHDAY.setFullYear( MAXBIRTHDAY.getFullYear() - 12 );

// Min associated subscription date
const MINSUBSCRIPTIONDAY = new Date();
MINSUBSCRIPTIONDAY.setMonth( MINSUBSCRIPTIONDAY.getMonth() - 11 );

const MyInnerForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    setFieldValue,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  } = props;

  useEffect(() => {
    if(values.ricevuta == null){
      adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/maxricevuta')
      .then(result=>{return result.json()})
        .then(max => {
          setFieldValue('ricevuta', max[0].max + 1, false);
        });
    }
  });

  return (
      <div>
      <Container className="border" fluid="true">    
      <form className="m-3" onSubmit={handleSubmit}>
      <Alert color="success" isOpen={(errors.fetchok!=null) ? true : false}>
        {errors.fetchok} <NavLink to="/associati">Torna a elenco associati</NavLink>
      </Alert>
      <Alert color="danger" isOpen={(errors.fetchko!=null) ? true : false}>
        {errors.fetchko}
      </Alert>
      <FormGroup row>
          <Col>
          <Label for="nome">Nome</Label>
          <Input
            id="nome"
            type="text"
            value={values.nome}
            className="text-capitalize"
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.nome && touched.nome}
            invalid={errors.nome && touched.nome}
          />
          <FormFeedback>{errors.nome}</FormFeedback>
          </Col>
          <Col>
          <Label for="cognome">Cognome</Label>
          <Input
            id="cognome"
            type="text"
            className="text-capitalize"
            value={values.cognome}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.cognome && touched.cognome}
            invalid={errors.cognome && touched.cognome}
          />
          <FormFeedback>{errors.cognome}</FormFeedback>
          </Col>
      </FormGroup>
      <FormGroup row>
      <Col>  
          <Label for="luogo_nascita">Luogo di Nascita</Label>
          <Input
            id="luogo_nascita"
            type="text"
            value={values.luogo_nascita}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.luogo_nascita && touched.luogo_nascita}
            invalid={errors.luogo_nascita && touched.luogo_nascita}
           />
          <FormFeedback>{errors.luogo_nascita}</FormFeedback>
      </Col>  
      <Col>
          <Label for="datan">Data Nascita</Label>
          <Input
            id="datan"
            placeholder="dd/mm/aaaa"
            type="date"
            value={values.datan}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.datan && touched.datan}
            invalid={errors.datan && touched.datan}
           />
          <FormFeedback>{errors.datan}</FormFeedback>
      </Col>
      </FormGroup>

      <FormGroup row>
      <Col>      
          <Label for="codice_fiscale">Codice Fiscale</Label>
          <Input
            id="codice_fiscale"
            type="text"
            className="text-uppercase"
            value={values.codice_fiscale}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.codice_fiscale && touched.codice_fiscale}
            invalid={errors.codice_fiscale && touched.codice_fiscale}
           >
         </Input>
          <FormFeedback>{errors.codice_fiscale}</FormFeedback>
        </Col>      
      </FormGroup>

      <FormGroup row>
        <Col>  
          <Label for="indirizzo">Indirizzo</Label>
          <Input
            id="indirizzo"
            type="text"
            value={values.indirizzo}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.indirizzo && touched.indirizzo}
            invalid={errors.indirizzo && touched.indirizzo}
           />
          <FormFeedback>{errors.indirizzo}</FormFeedback>
        </Col>  
      </FormGroup>

      <FormGroup row>
      <Col sm="12" md="4">      
          <Label for="cap">CAP</Label>
          <Input
            id="cap"
            type="text"
            value={values.cap}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.cap && touched.cap}
            invalid={errors.cap && touched.cap}
           >
         </Input>
          <FormFeedback>{errors.cap}</FormFeedback>
        </Col>      
        <Col sm="12" md="4">
        <Label for="telefono">Telefono</Label>
          <Input
            id="telefono"
            type="text"
            value={values.telefono}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.telefono && touched.telefono}
            invalid={errors.telefono && touched.telefono}
          />
          <FormFeedback>{errors.telefono}</FormFeedback>
        </Col>   
        <Col sm="12" md="4">  
          <Label for="email">Email</Label>
          <Input
            id="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.email && touched.email}
            invalid={errors.email && touched.email}
           />
          <FormFeedback>{errors.email}</FormFeedback>
        </Col>  
       </FormGroup>
       <FormGroup row>
       <Col sm="12" md="4">
          <Label for="datai">Data Iscrizione</Label>
          <Input
            id="datai"
            placeholder="dd/mm/aaaa"
            type="date"
            value={values.datai}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.datai && touched.datai}
            invalid={errors.datai && touched.datai}
           />
          <FormFeedback>{errors.datai}</FormFeedback>
      </Col>
      <Col sm="12" md="4">
          <Label for="ricevuta">Ricevuta</Label>
          <Input
            id="ricevuta"
            type="number"
            value={values.ricevuta}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.ricevuta && touched.ricevuta}
            invalid={errors.ricevuta && touched.ricevuta}
           />
          <FormFeedback>{errors.ricevuta}</FormFeedback>
       </Col>   
        <Col sm="12" md="4">      
          <Label for="importo">Importo</Label>
          <Input
            id="importo"
            type="select"
            value={values.importo}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.importo && touched.importo}
            invalid={errors.importo && touched.importo}
           >
           <option value="">Scegli</option>
           <option value="30">30 euro (ordinario ante 2024)</option>
           <option value="40">40 euro (sostenitore ante 2024)</option>
           <option value="55">55 euro (ordinario)</option>
           <option value="60">60 euro (sostenitore)</option>
         </Input>
          <FormFeedback>{errors.importo}</FormFeedback>
        </Col>      
      </FormGroup>
       <FormGroup row>
        <Col>  
          <Label for="note">Note</Label>
          <Input
            id="note"
            type="text"
            value={values.note}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.note && touched.note}
            invalid={errors.note && touched.note}
           />
          <FormFeedback>{errors.note}</FormFeedback>
        </Col>  
      </FormGroup>

      <FormGroup check row>
      <Col>  
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
          Inserisci
        </Button>{' '}
        <Button onClick={handleReset} disabled={!dirty || isSubmitting} color="secondary">
          Cancella
        </Button>{' '}
        <NavLink to="/associati">
          <Button disabled={isSubmitting} color="danger">
            Annulla
          </Button>
        </NavLink>
      </Col>
      </FormGroup>
    </form>
    </Container>
    </div>
   );
};

const NuovoAssociato = withFormik({
  mapPropsToValues: () => ({ nome: '',cognome:'',telefono:'',email: '',ricevuta: null,
                            datai:'',importo:'55',datan: '', note: '',
                            luogo_nascita:'', indirizzo:'', cap:'', codice_fiscale:'',
                           }),
  validationSchema: Yup.object().shape({
      nome: Yup.string()
      .required('Nome obbligatorio'),
      cognome: Yup.string()
      .required('Cognome obbligatorio'),
      telefono: Yup.string()
      .matches(/^[0-9]{5,10}$/,
               'Numero di telefono non valido')
      .required('Telefono obbligatorio'),
    email: Yup.string()
      .email('Indirizzo email non valido'),
    datai: Yup.date()
      .required('Data iscrizione obbligatoria')
      .min(MINSUBSCRIPTIONDAY,'La data iscrizione non può essere antecedente a più di 11 mesi fa')
      .max(new Date(),'La data iscrizione non può essere nel futuro'),
    datan: Yup.date()
      .max(MAXBIRTHDAY,'Data nascita non valida: l\'età minima è di 13 anni'),
    ricevuta: Yup.number().required().positive().integer()
      .required('Numero ricevuta non valido'),
    importo: Yup.number()
      .required('Importo obbligatorio'),
    indirizzo: Yup.string()
      .max(100, 'L\'indirizzo può contenere al massimo 100 caratteri').nullable(),      
    cap: Yup.string()
      .max(5, 'Il codice postale può contenere al massimo 5 caratteri').nullable(),      
    luogo_nascita: Yup.string()
      .max(100, 'il luogo di nascita può contenere al massimo 100 caratteri').nullable(),      
    note: Yup.string()
      .max(100, 'Le note devono avere al massimo 100 caratteri').nullable(),      
    codice_fiscale: Yup.string()
      .matches(/^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
       'Codice fiscale non valido').nullable(),      
  }),
  handleSubmit: (values, { setSubmitting, resetForm, setErrors }) => {
      adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/inserisciassociato', {
          method: 'post',
          headers: {
              'Accept': 'application/json, application/xml, text/play, text/html, *.*',
              'Content-Type': 'application/json'
              },
          body: JSON.stringify(values),
      }).then(result=>{
          setSubmitting(false);
          if(result.status === 200){
            resetForm();
            setErrors({fetchok: "Il nuovo associato è stato inserito."});
          }else if(result.status === 401){
            setErrors({fetchko: "Sessione scaduta"});
          }
          else{
            result.text()
            .then(msg=>{
              var errmsg = "Si è verificato un problema durante l'inserimento dell'associato.";
              if(msg){
                errmsg += " " + msg; 
              }
              setErrors({fetchko: errmsg});
            })
          }
      });      
  },
  displayName: 'BasicForm', // helps with React DevTools
})(MyInnerForm);
export default NuovoAssociato;