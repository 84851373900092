import React from 'react';
import { Alert, Form, FormFeedback, Container, Col, Button, FormGroup, Label, Input} from 'reactstrap';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { adalApiFetch } from './adalConfig';
import dateFormat from 'dateformat';

const MyInnerForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  } = props;
  return (
      <Container>
      <Col className="border">    
      <Form className="m-3" onSubmit={handleSubmit}>
      <Alert color="danger" isOpen={(errors.fetch!=null) ? true : false}>
        {errors.fetch}
      </Alert>
      <FormGroup row>
          <Col>
          <Label for="nome">Nome</Label>
          <Input
            id="nome"
            type="text"
            className="text-capitalize"
            value={values.nome}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.nome && touched.nome}
            invalid={errors.nome && touched.nome}
          />
          <FormFeedback>{errors.nome}</FormFeedback>
          </Col>
          
          <Col>
          <Label for="cognome">Cognome</Label>
          <Input
            id="cognome"
            type="text"
            className="text-capitalize"
            value={values.cognome}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.cognome && touched.cognome}
            invalid={errors.cognome && touched.cognome}
          />
          <FormFeedback>{errors.cognome}</FormFeedback>
          </Col>
      </FormGroup>
      <FormGroup row>
      <Col sm="6" md="8">
          <Label for="note">Luogo di Nascita</Label>
          <Input
            id="luogo_nascita"
            type="text"
            value={values.luogo_nascita}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.luogo_nascita && touched.luogo_nascita}
            invalid={errors.luogo_nascita && touched.luogo_nascita}
           />
          <FormFeedback>{errors.note}</FormFeedback>
       </Col>   
       <Col sm="6" md="4">
          <Label for="data_nascita">Data Nascita</Label>
          <Input
            id="data_nascita"
            placeholder="dd/mm/aaaa"
            type="date"
            value={values.data_nascita}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.data_nascita && touched.data_nascita}
            invalid={errors.data_nascita && touched.data_nascita}
           />
          <FormFeedback>{errors.data_nascita}</FormFeedback>
      </Col>
      </FormGroup>
      <FormGroup row>
      <Col>
          <Label for="codice_fiscale">Codice fiscale</Label>
          <Input
            id="codice_fiscale"
            type="text"
            className="text-uppercase"
            value={values.codice_fiscale}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.codice_fiscale && touched.codice_fiscale}
            invalid={errors.codice_fiscale && touched.codice_fiscale}
           />
          <FormFeedback>{errors.codice_fiscale}</FormFeedback>
      </Col>
      </FormGroup>
      <FormGroup row>
      <Col sm="12" md="12">
          <Label for="note">Indirizzo</Label>
          <Input
            id="indirizzo"
            type="text"
            value={values.indirizzo}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.indirizzo && touched.indirizzo}
            invalid={errors.indirizzo && touched.indirizzo}
           />
          <FormFeedback>{errors.indirizzo}</FormFeedback>
       </Col>   
       </FormGroup>
      <FormGroup row>
      <Col sm="12" md="6">      
          <Label for="cap">CAP</Label>
          <Input
            id="cap"
            type="text"
            value={values.cap}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.cap && touched.cap}
            invalid={errors.cap && touched.cap}
           >
         </Input>
          <FormFeedback>{errors.cap}</FormFeedback>
        </Col>      
        <Col sm="12" md="6">
          <Label for="data_iscrizione">Data Iscrizione</Label>
          <Input
            id="data_iscrizione"
            type="date"
            value={values.data_iscrizione}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.data_iscrizione && touched.data_iscrizione}
            invalid={errors.data_iscrizione && touched.data_iscrizione}
          />
          <FormFeedback>{errors.data_iscrizione}</FormFeedback>
       </Col>   
       </FormGroup>
       <FormGroup row>
       <Col sm="12" md="6">
          <Label for="telefono">Telefono</Label>
          <Input
            id="telefono"
            type="text"
            value={values.telefono}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.telefono && touched.telefono}
            invalid={errors.telefono && touched.telefono}
          />
          <FormFeedback>{errors.telefono}</FormFeedback>
       </Col>   
       <Col sm="12" md="6">
          <Label for="data_scadenza">Data Scadenza</Label>
          <Input
            id="data_scadenza"
            placeholder="dd/mm/aaaa"
            type="date"
            disabled={true}
            value={dateFormat(values.data_scadenza, "yyyy-mm-dd")}
           />
       </Col>   
      </FormGroup>

      <FormGroup row>
      <Col>  
          <Label for="email">Email</Label>
          <Input
            id="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.email && touched.email}
            invalid={errors.email && touched.email}
           />
          <FormFeedback>{errors.email}</FormFeedback>
        </Col>  
       </FormGroup>

      <FormGroup row>
      <Col sm="12" md="12">
          <Label for="note">Note</Label>
          <Input
            id="note"
            type="text"
            value={values.note}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.note && touched.note}
            invalid={errors.note && touched.note}
           />
          <FormFeedback>{errors.note}</FormFeedback>
       </Col>   
      </FormGroup>

      <FormGroup check row>
      <Col>  
        <Button disabled={isSubmitting} color="primary">
            Salva
        </Button>{' '}
        <Button onClick={handleReset} disabled={!dirty || isSubmitting} color="secondary">
            Cancella
        </Button>{' '}
      </Col>
      </FormGroup>
    </Form>
    </Col>
    </Container>
  );
};

const ModificaAssociato = withFormik({
  mapPropsToValues: props => (
    { nome: props.nome,cognome:props.cognome,telefono:props.telefono
      ,email: props.email,idassociato: props.idassociato,note: props.note 
      ,data_nascita: props.data_nascita != null ? props.data_nascita : ''
      ,data_scadenza: props.data_scadenza, data_iscrizione: props.data_iscrizione
      ,luogo_nascita: props.luogo_nascita != null ? props.luogo_nascita : ''
      ,indirizzo: props.indirizzo != null ? props.indirizzo : ''
      ,cap: props.cap != null ? props.cap : ''
      ,codice_fiscale: props.codice_fiscale != null ? props.codice_fiscale : ''
    }
  ),
  validationSchema: Yup.object().shape({
      nome: Yup.string()
      .required('Nome obbligatorio'),
      cognome: Yup.string()
      .required('Cognome obbligatorio'),
      data_iscrizione: Yup.date()
      .min('2016-04-21','Data iscrizione non valida')
      .required('Data iscrizione obbligatoria'),
      telefono: Yup.string()
      .matches(/^[0-9]{5,10}$/,
               'Numero di telefono non valido')
      .required('Telefono obbligatorio'),
      email: Yup.string()
      .email('Indirizzo email non valido'),
      indirizzo: Yup.string()
      .max(100, 'L\'indirizzo può contenere al massimo 100 caratteri').nullable(),      
      cap: Yup.string()
      .max(5, 'Il codice postale può contenere al massimo 5 caratteri').nullable(),      
      luogo_nascita: Yup.string().nullable()
      .max(100, 'il luogo di nascita può contenere al massimo 100 caratteri').nullable(),      
      note: Yup.string()
      .max(100, 'Le note devono avere al massimo 100 caratteri').nullable(),      
      codice_fiscale: Yup.string()
      .matches(/^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
       'Codice fiscale non valido').nullable(),      
  }),
  handleSubmit: (values, { setSubmitting, resetForm, setErrors }) => {
      adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/modassociato', {
          method: 'post',
          headers: {
              'Accept': 'application/json, application/xml, text/play, text/html, *.*',
              'Content-Type': 'application/json'
              },
          body: JSON.stringify(values),
      }).then(result=>{
          setSubmitting(false);
          if(result.status === 200){
            window.location.href="/associati/";
          }else if(result.status === 401){
            setErrors({fetchko: "Sessione scaduta"});
          }else{
            resetForm();
            setErrors({fetch: 'Si è verificato un errore'});
          }
      });      
  },
  displayName: 'BasicForm', // helps with React DevTools
})(MyInnerForm);
export default ModificaAssociato;