import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { NavLink} from 'react-router-dom';
import { adalApiFetch } from './adalConfig';

const moment = require('moment');
moment.locale('it-IT');

function capitalize(mySentence){
  const words = mySentence.split(" ");
  
  for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0] ? words[i][0].toUpperCase() + words[i].substr(1).toLowerCase() : words[i];
  }

  return words.join(" ");
}

function dateFormatter(cell){
  return cell ? moment(cell).format("DD/MM/YYYY") : '';  
}

function upperCase(cell){
  return cell ? cell.toUpperCase() : '';  
}


const colonne = [{
  field: 'id',
  headerName: '#',
  width: 20,
},{
  field: 'cognome',
  headerName: 'Cognome',
  width: 180,
  renderCell: (params) => (
    <NavLink className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" 
      to={'/associato/'+params.row.id}>{capitalize(params.value)}</NavLink>
  ),  
}, {
  field: 'nome',
  headerName: 'Nome',
  width: 180,
  renderCell: (params) => (
    <NavLink className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" 
      to={'/associato/'+params.row.id}>{capitalize(params.value)}</NavLink>
  ),  
}, {
  field: 'codiceFiscale',
  headerName: 'Codice Fiscale',
  width: 180,
  renderCell: (params) => (
    <NavLink className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" 
      to={'/associato/'+params.row.id}>{upperCase(params.value)}</NavLink>
  ),  
},{
  field: 'numRicevuta',
  headerName: 'Ricevuta',
  width: 80,
},{
  field: 'telefono',
  headerName: 'Telefono',
  width: 100,
},{
  field: 'email',
  headerName: 'Email',
  width: 240,
}, {
  field: 'dataIscrizione',
  headerName: 'Iscrizione',
  width: 100,
  valueFormatter: dateFormatter,
}, {
  field: 'dataScadenza',
  headerName: 'Scadenza',
  width: 100,
  valueFormatter: dateFormatter,
},];
class Associati extends React.Component {
  constructor() {
    super();
    this.state = {
      associati: [],
      loading: true,
    };
  }

  fetchData(iteration){
    if(iteration > 5) return;

    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/associati')
    .then(result=>{
      if(result.status == 401){
        setTimeout(this.fetchData(iteration+1), 3000);
        throw new Error("Error 401 -> retry "+iteration);
      }
      return result.json();
    })
    .then(listaAss => {
      var result = [];
      var i;
      
      for(i=0;i<listaAss.length; i++){
        result[i] = {};
        result[i].id = listaAss[i].id_associato;
        result[i].cognome = listaAss[i].cognome;
        result[i].nome = listaAss[i].nome;
        result[i].email = listaAss[i].email;
        result[i].telefono = listaAss[i].telefono;
        result[i].codiceFiscale = listaAss[i].codice_fiscale;
        result[i].numRicevuta = parseInt(listaAss[i].num_ricevuta, 10);
        result[i].dataIscrizione = new Date(listaAss[i].data_iscrizione);
        result[i].dataScadenza = new Date(listaAss[i].data_scadenza);
      }
      
      // dati appena letti dal servizio rest
      this.setState({associati: result});
      this.setState({loading: false});
    })
    .catch((error) => {
      console.error(error);
    });
  }

  componentDidMount() {
		this.fetchData(1);
  }

  render() {
    return (
      (this.state.loading) ? 
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Centra orizzontalmente
          alignItems: "flex-start", // Allinea in alto
          height: "100vh", // Altezza della pagina intera
          paddingTop: "40px", // Spaziatura dal bordo superiore (regolabile)
          backgroundColor: "background.default", // Facoltativo, per uno sfondo uniforme
        }}
      >
        <CircularProgress size="30px" />
      </Box>
      :     
      <DataGrid
          rows={this.state.associati}
          columns={colonne}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[10, 20, 50]}
        />
    );
  }
}
export default Associati;
