import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Container, Row, Col, Button } from 'reactstrap';
import { adalApiFetch } from './adalConfig';
import './comunicazioni.css';
import iconGreen from './images/iconfinder_Circle_Green_34211.png';
import iconRed from './images/iconfinder_Circle_Red_34214.png';
import iconGrey from './images/iconfinder_Circle_Grey_34212.png';
import moment from 'moment';

moment.locale('it-IT');

const columns = [
  {
    field: 'destinatario',
    headerName: 'Destinatario',
    width: 200,
    renderCell: (params) => (
      params.value != '-' ? (
        <a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" 
          href={'/associato/' + params.row.id_associato}>{params.value}</a>
      ) : (
        <span>{params.value}</span> // Mostra il valore senza link se '-'
      )
    ),
  },
  {
    field: 'recapito',
    headerName: 'Recapito',
    width: 260,
    renderCell: (params) => (
      <a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" 
        href={'/dettagliocomunicazione/' + params.row.id}>{params.value}</a>
    ),
  },
  {
    field: 'num_ricevuta',
    headerName: 'Ricevuta',
    width: 100,
    renderCell: (params) => (
      params.value != '-' ? (
        <a class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover" 
          href={'/associato/' + params.row.id_associato}>{params.value}</a>
      ) : (
        <span>{params.value}</span> // Mostra il valore senza link se '-'
      )
    ),
  },
  {
    field: 'scadenza',
    headerName: 'Scadenza',
    width: 150,
    valueFormatter: (value) => moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'invio',
    headerName: 'Data invio',
    width: 150,
    valueFormatter: (value) => moment(value).format('DD/MM/YYYY'),
  },
  { field: 'tipo', headerName: 'Tipo', width: 100 },
  {
    field: 'esito_invio',
    headerName: 'Esito',
    renderCell: (params) => {
      const status = params.value;
      const row = params.row;
      if (status === 'OK')
        return (
          <a href={'/dettagliocomunicazione/' + row.id}>
            <img src={iconGreen} width="24" alt="Esito OK" />
          </a>
        );
      else if (status === 'KO')
        return (
          <a href={'/dettagliocomunicazione/' + row.id}>
            <img src={iconRed} width="24" alt="Esito KO" />
          </a>
        );
      else
        return (
          <a href={'/dettagliocomunicazione/' + row.id}>
            <img src={iconGrey} width="24" alt="Esito sconosciuto" />
          </a>
        );
    },
  },
];

class ArchivioComunicazioni extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitRicicla = this.handleSubmitRicicla.bind(this);
    this.handleSubmitRigenera = this.handleSubmitRigenera.bind(this);
    this.setSubmitting = this.setSubmitting.bind(this);
  }

  state = {
    comunicazioni: [],
    isSubmitting: false,
    selected: [],
    loading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/comunicazioniinviate')
      .then((result) => result.json())
      .then((comunicazioni) => {
        const result = comunicazioni.map((com) => ({
          id: com.id_comunicazione,
          id_associato: com.id_associato,
          tipo: com.descrizione,
          canale: com.canale,
          oggetto: com.oggetto,
          num_ricevuta: com.num_ricevuta ? parseInt(com.num_ricevuta, 10) : '-',
          recapito: com.recapito,
          esito_invio: com.esito_invio,
          invio: new Date(com.data_invio),
          scadenza: new Date(com.data_scadenza),
          destinatario: (com.nome && com.cognome) ? `${com.nome} ${com.cognome}` : '-',
        }));

        this.setState({ comunicazioni: result, loading: false });
      });
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  handleSelectionModelChange = (newSelection) => {
    this.setState({ selected: newSelection });
  };

  handleSubmitRicicla() {
    this.setSubmitting(true);

    const { selected } = this.state;
    const comunicazioni = selected.map((id) => ({ id_comunicazione: id }));

    if (comunicazioni.length > 0) {
      adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/riciclacomunicazioni', {
        method: 'post',
        headers: {
          Accept: 'application/json, application/xml, text/play, text/html, *.*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comunicazioni }),
      }).then((result) => {
        this.setSubmitting(false);
        if (result.status === 200) {
          window.document.location.href = '/archiviocomunicazioni/';
        } else {
          alert('Si è verificato un errore!');
        }
      });
    } else {
      this.setSubmitting(false);
    }
  }

  handleSubmitRigenera() {
    this.setSubmitting(true);

    const { selected } = this.state;
    const comunicazioni = selected.map((id) => ({ id_comunicazione: id }));

    if (comunicazioni.length > 0) {
      adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/rigeneracomunicazioni', {
        method: 'post',
        headers: {
          Accept: 'application/json, application/xml, text/play, text/html, *.*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comunicazioni }),
      }).then((result) => {
        this.setSubmitting(false);
        if (result.status === 200) {
          window.document.location.href = '/archiviocomunicazioni/';
        } else {
          alert('Si è verificato un errore!');
        }
      });
    } else {
      this.setSubmitting(false);
    }
  }

  render() {
    const { comunicazioni, loading, isSubmitting, selected } = this.state;

    return (
      loading ?
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Centra orizzontalmente
            alignItems: "flex-start", // Allinea in alto
            height: "100vh", // Altezza della pagina intera
            paddingTop: "40px", // Spaziatura dal bordo superiore (regolabile)
            backgroundColor: "background.default", // Facoltativo, per uno sfondo uniforme
          }}
        >
          <CircularProgress size="30px" />
        </Box>
        :
        <Container fluid>
        <Row>
          <Col>
            <Button color="warning" onClick={this.handleSubmitRicicla} disabled={isSubmitting}>
              Rigenera selezionate
            </Button>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {/* Add any additional buttons here if needed */}
          </Col>
        </Row>
        <DataGrid
          rows={comunicazioni}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'invio', sort: 'desc' }],
            },
            }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          pageSize={10}
          checkboxSelection
          onRowSelectionModelChange={this.handleSelectionModelChange}
          pageSizeOptions={[10, 20, 50]}
          getRowId={(row) => row.id}
        />
      </Container>
    );
  }
}

export default ArchivioComunicazioni;