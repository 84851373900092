import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Container, Row, Col, Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { adalApiFetch } from './adalConfig';
import './comunicazioni.css';

const moment = require('moment');
moment.locale('it-IT');

function capitalize(mySentence) {
  const words = mySentence.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0] ? words[i][0].toUpperCase() + words[i].substr(1) : words[i];
  }
  return words.join(" ");
}

function nameFormatter(value) {
  return capitalize(value);
}

function dateFormatter(value) {
  return moment(value).format("DD/MM/YYYY");
}

const Comunicazioni = (props) => {
  const [comunicazioni, setComunicazioni] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState([]);
  const [nuscita, setNuscita] = useState(0);
  const [ninviate, setNinviate] = useState(0);
  const [ncestinate, setNcestinate] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/comunicazionidaapprovare')
      .then(result => result.json())
      .then(comunicazioni => {
        const formattedComunicazioni = comunicazioni.map(comunicazione => ({
          id: comunicazione.id_comunicazione,
          tipo: comunicazione.descrizione,
          canale: comunicazione.canale,
          oggetto: comunicazione.oggetto,
          testo: comunicazione.testo,
          recapito: comunicazione.recapito,
          num_ricevuta: comunicazione.num_ricevuta ? parseInt(comunicazione.num_ricevuta, 10) : '-',
          data: new Date(comunicazione.data_scadenza),
          destinatario: (comunicazione.nome && comunicazione.cognome)  ? comunicazione.nome + ' ' + comunicazione.cognome : '-',
        }));
        setComunicazioni(formattedComunicazioni);
        setLoading(false);
      });

    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/ncomunicazioniuscita')
      .then(result => result.json())
      .then(uscita => {
        if (uscita[0].num) {
          setNuscita(uscita[0].num);
        }
      });

    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/ncomunicazioniinviate')
      .then(result => result.json())
      .then(uscita => {
        if (uscita[0].num) {
          setNinviate(uscita[0].num);
        }
      });

    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/ncomunicazionicestinate')
      .then(result => result.json())
      .then(uscita => {
        if (uscita[0].num) {
          setNcestinate(uscita[0].num);
        }
      });
  };

  const handleSelectionChange = (selection) => {
    setSelected(selection);
  };

  const handleSubmit = (url) => {
    setIsSubmitting(true);

    if (selected.length > 0) {
      const comunicazioni = selected.map(id => ({ id_comunicazione: id }));

      adalApiFetch(fetch, url, {
        method: 'post',
        headers: {
          'Accept': 'application/json, application/xml, text/play, text/html, *.*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ comunicazioni }),
      }).then(result => {
        setIsSubmitting(false);
        if (result.status === 200) {
          window.document.location.href = "/comunicazioni/";
        } else {
          alert("Si è verificato un errore!");
        }
      });
    } else {
      setIsSubmitting(false);
    }
  };

  const columns = [
    { field: 'destinatario', headerName: 'Destinatario', width: 200, valueFormatter: nameFormatter  },
    { field: 'recapito', headerName: 'Recapito', width: 260, sortable: true},
    { field: 'num_ricevuta', headerName: 'Ricevuta', width: 100 },
    { field: 'data', headerName: 'Scadenza', width: 100, valueFormatter: dateFormatter },
    { field: 'tipo', headerName: 'Tipo', width: 90, sortable: true },
    { field: 'testo', headerName: 'Testo', width: 500, sortable: false },
  ];

  return (
    loading ?
    <Box
      sx={{
        display: "flex",
        justifyContent: "center", // Centra orizzontalmente
        alignItems: "flex-start", // Allinea in alto
        height: "100vh", // Altezza della pagina intera
        paddingTop: "40px", // Spaziatura dal bordo superiore (regolabile)
        backgroundColor: "background.default", // Facoltativo, per uno sfondo uniforme
      }}
    >
      <CircularProgress size="30px" />
    </Box>
    :
    <Container fluid>
        <DataGrid
          rows={comunicazioni}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selected}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'data', sort: 'asc' }],
            },
          }}
          onRowSelectionModelChange={handleSelectionChange}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSize={10}
          pageSizeOptions={[10, 20, 50]}
        />
      <Row>
        <Col>
          <Button color="primary" onClick={() => handleSubmit(process.env.REACT_APP_BASE_SERVER_URI + '/approvacomunicazioni')} disabled={isSubmitting}>
            Approva selezionate
          </Button>
          {' '}
          <Button color="warning" onClick={() => handleSubmit(process.env.REACT_APP_BASE_SERVER_URI + '/cestinacomunicazioni')} disabled={isSubmitting}>
            Cestina selezionate
          </Button>
          {' '}
          <Button color="warning" onClick={() => handleSubmit(process.env.REACT_APP_BASE_SERVER_URI + '/riciclacomunicazioni')} disabled={isSubmitting}>
            Rigenera selezionate
          </Button>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Link to="/cestinocomunicazioni">
            <Button color="warning" outline>
              Cestinate <Badge color="warning">{ncestinate}</Badge>
            </Button>
          </Link>
          {' '}
          <Button color="secondary" outline>
            In uscita <Badge color="secondary">{nuscita}</Badge>
          </Button>
          {' '}
          <Link to="/archiviocomunicazioni">
            <Button color="success" outline>
              Inviate <Badge color="success">{ninviate}</Badge>
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Comunicazioni;