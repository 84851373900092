import React from 'react';
import { adalApiFetch } from './adalConfig';
import Paper from '@material-ui/core/Paper';
import { Autocomplete, TextField, Typography, Grid } from '@mui/material';
import { ViewState, EditingState, GroupingState, IntegratedGrouping, IntegratedEditing 
} from '@devexpress/dx-react-scheduler';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import itLocale from 'date-fns/locale/it';
import {
  Scheduler,
  Resources,
  DayView,
  WeekView,
  MonthView,
  Toolbar,
  ViewSwitcher,
  GroupingPanel,
  DateNavigator,
  TodayButton,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';
import 'bootstrap/dist/css/bootstrap.min.css';
import { format, isSameDay, isBefore } from 'date-fns';
import { blue, orange, red } from '@mui/material/colors';
import { CSVLink } from 'react-csv';

const csv_headers = [
     { label: 'Cognome', key: 'cognome' },
     { label: 'Nome', key: 'nome' },
     { label: 'Data di Nascita', key: 'dataNascita' },
     { label: 'Codice Fiscale', key: 'codiceFiscale' },
     { label: 'Telefono', key: 'telefono' },
     { label: 'Data Visita', key: 'dataVisita' },
     { label: 'Ora Visita', key: 'oraVisita' },
   ];
   
const resources = [{
     fieldName: 'roomId',
     title: 'Ambulatorio',
     instances: [
       { text: 'Medico 1', id: 1, color: blue },
       { text: 'Medico 2', id: 2, color: orange },
     ],
   }];

const grouping = [{
     resourceName: 'roomId',
}];

const Content = ({appointmentData, ...restProps}) => (
     <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
          <Grid container alignItems="center">
               <Grid item xs={2}>
                    {' '}
               </Grid>
               <Grid item xs={10}>
                    <span>{appointmentData.note}</span>
               </Grid>          
          </Grid>          
     </AppointmentTooltip.Content>
);

const messages = {
     commitCommand: 'Salva',     
};

function extractCsvData(listaVisite, date){
     const csvData = [];
     var i;
          
     for(i=0;i<listaVisite.length; i++){
          if(isSameDay(date, new Date(listaVisite[i].data_visita))){
               const row = {};
               row.dataVisita = format(date, "dd/MM/yyyy");
               row.oraVisita = listaVisite[i].ora_visita.slice(11,16);
               row.nome = listaVisite[i].nome;
               row.cognome = listaVisite[i].cognome;
               row.telefono = listaVisite[i].telefono;
               row.dataNascita = format(new Date(listaVisite[i].data_nascita), "dd/MM/yyyy");
               row.codiceFiscale = listaVisite[i].codice_fiscale;
               csvData.push(row);
          }
     }

     return csvData
}

function capitalize(mySentence){
     const words = mySentence.split(" ");
     
     for (let i = 0; i < words.length; i++) {
         words[i] = words[i][0] ? words[i][0].toUpperCase() + words[i].substr(1).toLowerCase() : words[i];
     }
   
     return words.join(" ");
}

const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
     const [rooms, setRooms] = React.useState([{id: 1, label: 'Medico 1'}, {id: 2, label: 'Medico 2'}]);
     const [assoToVisit, setAssoToVisit] = React.useState([]);
     const [associated, setAssociated] = React.useState(null);
     const [room, setRoom] = React.useState(appointmentData.roomId ? rooms.find(r => r.id == appointmentData.roomId) : null);
     
     const onAssociatedChange = (event, nextValue) => {
          onFieldChange({ associated: nextValue });
          onFieldChange({ title: nextValue.label });
          setAssociated(nextValue);
        };
     const onStartDateChange = (nextValue) => {
          onFieldChange({ startDate: nextValue });
        };
     const onEndDateChange = (nextValue) => {
          onFieldChange({ endDate: nextValue });
        };
     const onNoteChange = (event) => {
          onFieldChange({ note: event.target.value });
        };
     const onRoomChange = (event, nextValue) => {
          onFieldChange({ roomId: nextValue.id });
          setRoom(nextValue);
        };
     
     React.useEffect(() => {
          const fetchAssociati = async () => {
               try {
               const response = await adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/visits/associatidavisitare');
               const listaAss = await response.json();
          
               let result = [];
          
               if (appointmentData.associated) {
                    result.push(appointmentData.associated);
               }
          
               // associati da visitare
               for (let i = 0; i < listaAss.length; i++) {
                    let a = {
                    id: listaAss[i].id_associato,
                    label: `${capitalize(listaAss[i].nome)} ${capitalize(listaAss[i].cognome)} (${listaAss[i].telefono})`,
                    data_scadenza: new Date(listaAss[i].data_scadenza) // Aggiungi il campo data_scadenza
                    };
                    result.push(a);
               }
          
               setAssoToVisit(result);
               } catch (error) {
               console.error('Errore nel fetch degli associati:', error);
               }
          };
          
          fetchAssociati();
     }, []);
        
     React.useEffect(() => {
          if(!associated){
               setAssociated((appointmentData.associated && assoToVisit.length > 0) ?
                    assoToVisit.find(a => a.id == appointmentData.associated.id) : null);
          }
     });

     const today = new Date();

     return <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
          <Grid container spacing={2} direction="row" justifyContent="space-between">
               <Grid item xs={12} md={12}>
               <Typography variant="h5" gutterBottom component="div">
                    {format(new Date(appointmentData.startDate), 'iiii, dd MMMM yyyy', {locale: itLocale})}
               </Typography>
               </Grid>               
               <Grid item xs={6} md={6}>
               <TimePicker
                    label="Ora inizio"
                    value={appointmentData.startDate}
                    onChange={onStartDateChange}
                    slotProps={{ textField: { variant: 'outlined' } }}
               />
               </Grid>               
               <Grid item xs={12} md={12}>
               <Autocomplete
                    disablePortal
                    disableClearable
                    value={associated}
                    fullWidth
                    id="associato"
                    options={assoToVisit}
                    onChange={onAssociatedChange}
                    renderOption={(props, option) => (
                         <li 
                              {...props} 
                              style={{
                                   color: isBefore(option.data_scadenza, today) ? red[500] : 'inherit'
                              }}
                         >
                              {option.label}
                         </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Associato"/>}
                    />
               </Grid>               
               <Grid item xs={12} md={12}>
               <Autocomplete
                    disablePortal
                    disableClearable
                    value={room}
                    fullWidth
                    id="room"
                    options={rooms}
                    onChange={onRoomChange}
                    renderInput={(params) => <TextField {...params} label="Medico"/>}
                    />
               </Grid>               
               <Grid item xs={12} md={12}>
               <TextField
                    id="note"
                    label="Note"
                    placeholder="Note"
                    value={appointmentData.note}
                    margin="normal"
                    onChange={onNoteChange}
                    multiline
                    fullWidth
                    rows={4}
               />               
               </Grid>               
          </Grid>
          </LocalizationProvider>          
};

class Visite extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               currentDate: new Date(),
               appointments: [],
               addedAppointment: {},
               appointmentChanges: {},
               editingAppointment: undefined,
               csvData: [],
          };
          this.currentDateChange = this.currentDateChange.bind(this);
          this.fetchData = this.fetchData.bind(this);
          this.commitChanges = this.commitChanges.bind(this);
          this.addAppointment = this.addAppointment.bind(this);
          this.delAppointment = this.delAppointment.bind(this);
          this.updAppointment = this.updAppointment.bind(this);
     }

     currentDateChange(currentDate){ 
          this.fetchData(currentDate);
          this.setState({ currentDate }); 
     };          

	componentDidMount() {
          this.fetchData(this.state.currentDate);
	}     
    
     fetchData(date){
          const datei = new Date(date).setDate(date.getDate());
          const datef = new Date(date).setDate(date.getDate());
          adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+
                    '/visits/appuntamenti?datai='+format(datei,"yyyy-MM-dd")
                    +'&dataf='+format(datef,"yyyy-MM-dd"))
          .then(result=>{return result.json()})
          .then(listaVisite => {
               var result = [];
               var i, endDate;
               
               for(i=0;i<listaVisite.length; i++){
                    result[i] = {};
                    result[i].id = listaVisite[i].id_visita;
                    result[i].startDate = new Date(listaVisite[i].data_visita.slice(0,11)+listaVisite[i].ora_visita.slice(11,19));
                    endDate = new Date(result[i].startDate);
                    endDate.setMinutes(endDate.getMinutes() + 10);
                    result[i].endDate = endDate;
                    result[i].title = listaVisite[i].nome + ' ' + listaVisite[i].cognome;
                    result[i].associated = {id: listaVisite[i].id_associato, label: result[i].title};
                    result[i].telefono = listaVisite[i].telefono;
                    result[i].email = listaVisite[i].email;
                    result[i].tipo = listaVisite[i].tipo;
                    result[i].stato = listaVisite[i].stato;
                    result[i].note = listaVisite[i].note;
                    result[i].roomId = listaVisite[i].id_ambulatorio;
               }
               
               this.setState({appointments: Object.values(result), csvData: extractCsvData(listaVisite, date)});
          });
     }

     addAppointment(a){
          adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/visits/insappointment', {
			method: 'post',
			headers: {
				'Accept': 'application/json, application/xml, text/play, text/html, *.*',
				'Content-Type': 'application/json'
				},
			body: JSON.stringify({appuntamento: {
                              data: a.startDate, 
                              orario: format(a.startDate, 'H:m', {locale: itLocale}),
                              id_associato: a.associated.id,
                              tipoVisita: 1,
                              note: a.note,
                              id_ambulatorio: a.roomId,
                         }}),
		}).then(result=>{
			if(result.status === 200){ 	
                 this.fetchData(this.state.currentDate);
			}else{
			  alert("Si è verificato un errore!");
			}
		}); 		          
     }

     updAppointment(a){
          adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/visits/updappointment', {
			method: 'post',
			headers: {
				'Accept': 'application/json, application/xml, text/play, text/html, *.*',
				'Content-Type': 'application/json'
				},
			body: JSON.stringify({appuntamento: {
                              id_appointment: a.id,
                              data: a.startDate, 
                              orario: format(a.startDate, 'H:m', {locale: itLocale}),
                              id_associato: a.associated.id,
                              note: a.note,
                              id_ambulatorio: a.roomId,
                         }}),
		}).then(result=>{
			if(result.status === 200){ 	
                 this.fetchData(this.state.currentDate);
			}else{
			  alert("Si è verificato un errore!");
			}
		}); 		          
     }

     delAppointment(d){
          adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/visits/delappointment', {
			method: 'post',
			headers: {
				'Accept': 'application/json, application/xml, text/play, text/html, *.*',
				'Content-Type': 'application/json'
				},
			body: JSON.stringify({id_appointment: d}),
		}).then(result=>{
			if(result.status === 200){ 	
                 this.fetchData(this.state.currentDate);
			}else{
			  alert("Si è verificato un errore!");
			}
		}); 		          
     }

     commitChanges({ added, changed, deleted }) {
          if (added) {
               this.addAppointment(added);               
          }

          if (deleted !== undefined) {
               this.delAppointment(deleted);               
          }

          if (changed !== undefined) {
               const id = Object.keys(changed)[0];
               if(id){
                    const changes = Object.keys(changed[id]);
                    const {appointments} = this.state;
                    const a = appointments.find(a => a.id == id);

                    if(a){
                         changes.forEach(k => a[k] = changed[id][k]);
                         this.updAppointment(a);               
                    }
               }
          }
     }

     render() {
          const { appointments, currentDate, csvData} = this.state;

          return <Paper>
               <div class="text-right pr-5">
                    <CSVLink data={csvData} headers={csv_headers} filename={"visite.csv"}>
                         Scarica visite per il {format(currentDate, 'dd MMMM yyyy', {locale: itLocale})}
                    </CSVLink>
               </div>
               <Scheduler data={appointments} locale={'it-IT'} height={700}>
                    <ViewState
                         defaultCurrentDate={currentDate}
                         defaultCurrentViewName="day"
                         onCurrentDateChange={this.currentDateChange}                         
                    />
                    <EditingState
                         onCommitChanges={this.commitChanges}
                    />                 
                    <GroupingState
                         grouping={grouping}
                         groupOrientation={() => 'Horizontal'}
                    />
                    <DayView
                         name="day"
                         displayName="Giorno"
                         height={10}
                         startDayHour={16}
                         endDayHour={20}
                         cellDuration={10}                    
                    />
                    
                    <Appointments />
                    <Resources
                         data={resources}
                         mainResourceName="roomId"
                    />
                    <IntegratedGrouping />
                    <IntegratedEditing />   
                    <AppointmentTooltip
                         contentComponent={Content}
                         showCloseButton
                         showOpenButton
                         showDeleteButton                    
                    />
                    <AppointmentForm
                         basicLayoutComponent={BasicLayout}
                         messages={messages}
                    />
                    <GroupingPanel />
                    <Toolbar />
                    <DateNavigator />
                    <TodayButton />
               </Scheduler>
               </Paper>
     }
}
export default Visite;