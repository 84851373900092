import React from 'react';
import Header from './Header';
import Main from './Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import { adalApiFetch } from './adalConfig';

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			nreq: 0,
			ncom: 0,
		};
	}

	componentDidMount(){
		document.title = "Happy - Il sorriso di Isa";
		this.fetchData();
	}

	fetchData = () => {
    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/utente')
    .then(result=>{return result.json()})
    .then(u => {
      this.setState({utente: u});
		});

    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/ncomunicazionidaapprovare')
    .then(result=>{return result.json()})
    .then(res => {
      if(res.count){
        this.setState({ncom: res.count});
      }
    });

		adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/requests/countRichiesteIscrizione')
    .then(result=>{return result.json()})
    .then(res => {
      if(res.count){
        this.setState({nreq: res.count});
      }
    });
	}

	// Funzione per aggiornare lo stato del componente padre
  updateHeaderData = () => {
    this.fetchData();
  };

	render() {
	return <div className="px-2">
		    <Header nreq={this.state.nreq} ncom={this.state.ncom}/>
		    <Main updateHeaderData={this.updateHeaderData}/>
		  </div>
	}
}
export default App