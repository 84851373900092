import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { adalApiFetch } from './adalConfig';
import './comunicazioni.css';

const moment = require('moment');
moment.locale('it-IT');

function capitalize(mySentence) {
  const words = mySentence.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0] ? words[i][0].toUpperCase() + words[i].substr(1) : words[i];
  }
  return words.join(" ");
}

function nameFormatter(value) {
  return value ? capitalize(value) : '';
}

function dateFormatter(value) {
  return value ? moment(value).format("DD/MM/YYYY") : '';
}

function fileFormatter(value) {
  return (
    <span title={value} role="button" className="text-primary">{value}</span>
  );
}

const FilePopup = ({ file, onClose }) => {
  const fileURL = file !== null ? window.URL.createObjectURL(file) : null;

  return (
    <Modal size="lg" fullscreen="lg" scrollable isOpen={file !== null} toggle={onClose} className="h-75" contentClassName="h-100">
      <ModalHeader toggle={onClose}>Ricevuta pagamento</ModalHeader>
      <ModalBody className="h-100">
        {file !== null && (
          <object data={fileURL} type={file.type} className="w-100 h-100">
            <p>Impossibile visualizzare il file. Scaricalo da <a href={fileURL}>qui</a></p>
          </object>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClose}>Chiudi</Button>
      </ModalFooter>
    </Modal>
  );
};

const RichiesteIscrizione = (props) => {
  const [richieste, setRichieste] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [fetchko, setFetchko] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    // Fetch per richieste di iscrizione
    const fetchIscrizione = adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/requests/richiesteiscrizione')
    .then(result => result.json().then(data => data.map(item => ({ ...item, tipo: 'Iscrizione' }))));

    // Fetch per richieste di rinnovo
    const fetchRinnovo = adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/requests/richiesterinnovo')
    .then(result => result.json().then(data => data.map(item => ({ ...item, tipo: 'Rinnovo' }))));

    // Accoda i risultati
    Promise.all([fetchIscrizione, fetchRinnovo])
    .then(([listaRichiesteIscrizione, listaRichiesteRinnovo]) => {
      const tutteLeRichieste = [...listaRichiesteIscrizione, ...listaRichiesteRinnovo];
      setRichieste(tutteLeRichieste);
      setLoading(false);
      setIsSubmitting(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
      setIsSubmitting(false);
    });
  };

  const handleDownloadFile = (idi, idr) => {
    const url = idi ? process.env.REACT_APP_BASE_SERVER_URI+'/requests/filepagamento/'+idi: process.env.REACT_APP_BASE_SERVER_URI+'/requests/filepagamentorinn/'+idr;
    adalApiFetch(fetch, url)
      .then(res => res.blob())
      .then(blob => {
        const file = new Blob([blob], { type: blob.type });
        setFile(file);
      })
      .catch(error => console.error('Error:', error));
  };

  const handleClosePopup = () => {
    setFile(null);
  };

  const handleSubmitApprova = () => {
    console.log(selected);
  
    if (selected) {
      setIsSubmitting(true);
      setLoading(true);
      let route = '';
      const { id, tipo } = selected; // Ottieni id e tipo dalla selezione

      if (tipo === 'Iscrizione') {
        route = '/requests/trasformarichiesta/';
      } else if (tipo === 'Rinnovo') {
        route = '/requests/trasformarichiestarinn/';
      }  
 
      adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + route + id, {
        method: 'put',
        headers: {
          'Accept': 'application/json, application/xml, text/play, text/html, *.*',
          'Content-Type': 'application/json'
        }
      })
        .then(result => {
          if (result.status !== 200) {
            result.text()
              .then(msg => {
                let errmsg = "Si è verificato un problema durante l'inserimento dell'associato.";
                if (msg) {
                  errmsg += " " + msg;
                }
                setFetchko(errmsg);
              });
          }
  
          setIsSubmitting(false);
          setSelected(null);
          fetchData();
          // Aggiorna il dato dell'header
          props.updateHeaderData();
        })
        .catch(error => {
          console.error('Error:', error);
          setLoading(false);
          setIsSubmitting(false);
        });
    }
  };
  
  const columns = [
    {
      field: 'tipo',
      headerName: 'Richiesta',
      width: 120,
      sortable: true,
    },
    {
      field: 'cognome',
      headerName: 'Cognome',
      width: 150,
      sortable: true,
      valueFormatter: nameFormatter,
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      sortable: true,
      valueFormatter: nameFormatter,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 220,
      sortable: true,
    },
    {
      field: 'codice_fiscale',
      headerName: 'Codice fiscale',
      width: 180,
      sortable: true,
      valueFormatter: (value) => {value ? value.toUpperCase() : ''},
    },
    {
      field: 'data_richiesta',
      headerName: 'Data richiesta',
      width: 150,
      sortable: true,
      valueFormatter: dateFormatter,
    },
    {
      field: 'importo',
      headerName: 'Importo',
      width: 100,
      sortable: true,
    },
    {
      field: 'nome_file_pagamento',
      headerName: 'Pagamento',
      width: 200,
      sortable: true,
      valueFormatter: ({ value }) => fileFormatter({ value }),
      renderCell: (params) => (
        <span
          title={params.value}
          role="button"
          className="text-primary"
          onClick={() => handleDownloadFile(params.row.id_richiestaiscrizione, params.row.id_richiestarinnovo)}
        >
          {params.value}
        </span>
      )
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 500,
      sortable: false,
    },
  ];

  // Crea un ID unico combinando tipo e id
  function getRowId(row) {
    return `${row.tipo}-${row.id_richiestaiscrizione || row.id_richiestarinnovo}`;
  }

  return (
    loading ?
    <Box
      sx={{
        display: "flex",
        justifyContent: "center", // Centra orizzontalmente
        alignItems: "flex-start", // Allinea in alto
        height: "100vh", // Altezza della pagina intera
        paddingTop: "40px", // Spaziatura dal bordo superiore (regolabile)
        backgroundColor: "background.default", // Facoltativo, per uno sfondo uniforme
      }}
    >
      <CircularProgress size="30px" />
    </Box>
    :
    <Container fluid>
      <FilePopup file={file} onClose={handleClosePopup} />
      <Alert color="danger" isOpen={fetchko !== null}>
          {fetchko}
      </Alert>
      <DataGrid
        rows={richieste}
        columns={columns}
        getRowId={getRowId}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        onRowSelectionModelChange={(newSelection) => {
          const selectedRow = richieste.find(r => getRowId(r) === newSelection[0]);
          if(selectedRow){
            setSelected({ id: selectedRow.id_richiestaiscrizione || selectedRow.id_richiestarinnovo, tipo: selectedRow.tipo }); // Salva l'id e il tipo
          }
        }}
        components={{
          Toolbar: GridToolbar
        }}
        pageSize={10}
        pageSizeOptions={[10, 20]}
        />
      <Row>
        <Col>
          <Button color="primary" onClick={handleSubmitApprova} disabled={isSubmitting}>
            Approva richiesta
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default RichiesteIscrizione;