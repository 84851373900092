import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Associati from './Associati'
import Visite from './Visite'
import NuovoAssociato from './NuovoAssociato'
import SchedaAssociato from './SchedaAssociato'
import Comunicazioni from './Comunicazioni'
import ArchivioComunicazioni from './ArchivioComunicazioni'
import CestinoComunicazioni from './CestinoComunicazioni'
import DettaglioComunicazione from './DettaglioComunicazione'
import RichiesteIscrizione from './RichiesteIscrizione'

const Main = (props) => (
	<main>
    <Routes>
      <Route exact path='/' element={<Associati/>}/>
      <Route path='/associati' element={<Associati/>}/>
      <Route path='/visite/:data' element={<Visite/>}/>
      <Route path='/visite' element={<Visite/>}/>
      <Route path='/comunicazioni' element={<Comunicazioni/>}/>
      <Route path='/archiviocomunicazioni' element={<ArchivioComunicazioni/>}/>
      <Route path='/cestinocomunicazioni' element={<CestinoComunicazioni/>}/>
      <Route path='/nuovoassociato' element={<NuovoAssociato/>}/>
      <Route path='/richiesteiscrizione' element={<RichiesteIscrizione updateHeaderData={props.updateHeaderData}/>} />
      <Route path='/associato/:id' element={<SchedaAssociato/>}/>
      <Route path='/dettagliocomunicazione/:id' element={<DettaglioComunicazione/>}/>
    </Routes>
  </main>
)

export default Main