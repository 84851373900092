import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Container, Row, Col, Button } from 'reactstrap';
import { adalApiFetch } from './adalConfig';
import './comunicazioni.css';
import moment from 'moment';

moment.locale('it-IT');

const columns = [
  { field: 'id', headerName: '#', hide: true },
  { field: 'destinatario', headerName: 'Destinatario', width: 200 },
  {
    field: 'recapito',
    headerName: 'Recapito',
    width: 260,
  },
  { field: 'num_ricevuta', headerName: 'Ricevuta' },
  {
    field: 'scadenza',
    headerName: 'Scadenza',
    valueFormatter: (value) => moment(value).format('DD/MM/YYYY'),
  },
  { field: 'tipo', headerName: 'Tipo', width: 100 },
  { field: 'canale', headerName: 'Canale', width: 100 },
];

class ArchivioComunicazioni extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitRicicla = this.handleSubmitRicicla.bind(this);
    this.setSubmitting = this.setSubmitting.bind(this);
  }

  state = {
    comunicazioni: [],
    selected: [],
    loading: true,
    isSubmitting: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/comunicazionicestinate')
      .then((result) => result.json())
      .then((comunicazioni) => {
        const result = comunicazioni.map((com) => ({
          id: com.id_comunicazione,
          tipo: com.descrizione,
          canale: com.canale,
          oggetto: com.oggetto,
          testo: com.testo,
          num_ricevuta: com.num_ricevuta ? parseInt(com.num_ricevuta, 10) : '-',
          recapito: com.recapito,
          scadenza: new Date(com.data_scadenza),
          destinatario: (com.nome && com.cognome) ? `${com.nome} ${com.cognome}` : '-',
        }));

        this.setState({ comunicazioni: result, loading: false });
      });
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  handleOnSelectionModelChange = (newSelection) => {
    this.setState({ selected: newSelection });
  };

  handleSubmitRicicla() {
    this.setSubmitting(true);

    const { selected } = this.state;
    const comunicazioni = selected.map((id) => ({ id_comunicazione: id }));

    if (comunicazioni.length > 0) {
      adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI + '/riciclacomunicazioni', {
        method: 'post',
        headers: {
          Accept: 'application/json, application/xml, text/play, text/html, *.*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ comunicazioni }),
      }).then((result) => {
        this.setSubmitting(false);
        if (result.status === 200) {
          window.document.location.href = '/cestinocomunicazioni/';
        } else {
          alert('Si è verificato un errore!');
        }
      });
    } else {
      this.setSubmitting(false);
    }
  }

  render() {
    const { comunicazioni, loading, isSubmitting, selected } = this.state;

    return (
      loading ?
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Centra orizzontalmente
          alignItems: "flex-start", // Allinea in alto
          height: "100vh", // Altezza della pagina intera
          paddingTop: "40px", // Spaziatura dal bordo superiore (regolabile)
          backgroundColor: "background.default", // Facoltativo, per uno sfondo uniforme
        }}
      >
        <CircularProgress size="30px" />
      </Box>
      :
      <Container fluid>
        <Row>
          <Col>
            <Button color="warning" onClick={this.handleSubmitRicicla} disabled={isSubmitting}>
              Rigenera selezionate
            </Button>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {/* Add any additional buttons here if needed */}
          </Col>
        </Row>
        <DataGrid
          rows={comunicazioni}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSize={10}
          checkboxSelection
          onRowSelectionModelChange={this.handleOnSelectionModelChange}
          pageSizeOptions={[10, 20, 50]}
        />
      </Container>
    );
  }
}

export default ArchivioComunicazioni;