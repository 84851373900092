import React, { useEffect } from 'react';
import { Alert, Form, FormFeedback, Container, Col, Button, FormGroup, Label, Input} from 'reactstrap';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { adalApiFetch } from './adalConfig';
import dateFormat from 'dateformat';

// Min associated subscription date
const MINRENEWALDAY = new Date();
MINRENEWALDAY.setMonth( MINRENEWALDAY.getMonth() - 11 );

const MyInnerForm = props => {
  const {
    values,
    touched,
    errors,
    dirty,
    setFieldValue,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
  } = props;

  function fetchData(idassociato){
    adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/ricevutaassociato?idassociato='+idassociato)
    .then(result=>{return result.json()})
    .then(ricevuta => {
      if(ricevuta[0]){
        const nuovaRicevuta = ricevuta[0].num_ricevuta + "/" + dateFormat(new Date(), "yy");
        setFieldValue('ricevuta', nuovaRicevuta, true);
      }
    });
  }
  
  useEffect(() => {
    if(!values.ricevuta){
      fetchData(props.idassociato);
    }

    if(values.datar != '' && !errors.datar && touched.datar){
      const datasOld = new Date(props.data_scadenza);
      const datar = new Date(values.datar);
      var datas = datasOld > datar ? datasOld : datar;
      datas.setFullYear(datas.getFullYear() + 1);

      if(values.datas != dateFormat(datas, "yyyy-mm-dd")){
        setFieldValue('datas', dateFormat(datas, "yyyy-mm-dd"), true);
      }
    }
  });

  return (
      <Container className="border">
      <Form className="m-3" onSubmit={handleSubmit}>
      <Alert color="danger" isOpen={(errors.fetch!=null) ? true : false}>
        {errors.fetch}
      </Alert>
      <FormGroup row>
      <Col>
          <Label for="datar">Data Rinnovo</Label>
          <Input
            id="datar"
            type="date"
            value={values.datar}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.datar && touched.datar}
            invalid={errors.datar && touched.datar}
           />
          <FormFeedback>{errors.datar}</FormFeedback>
      </Col>
       <Col xs={6}>      
          <Label for="importo">Importo</Label>
          <Input
            id="importo"
            type="select"
            value={values.importo}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.importo && touched.importo}
            invalid={errors.importo && touched.importo}
           >
           <option value="">Scegli</option>
           <option value="0">0 euro (recupero covid)</option>
           <option value="30">30 euro (ordinario ante 2024)</option>
           <option value="40">40 euro (sostenitore ante 2024)</option>
           <option value="55">55 euro (ordinario)</option>
           <option value="60">60 euro (sostenitore)</option>
         </Input>
          <FormFeedback>{errors.importo}</FormFeedback>
        </Col>      
        </FormGroup>
        <FormGroup row>
        <Col>
          <Label for="ricevuta">Ricevuta</Label>
          <Input
            id="ricevuta"
            type="text"
            value={values.ricevuta}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.ricevuta && touched.ricevuta}
            invalid={errors.ricevuta && touched.ricevuta}
           />
          <FormFeedback>{errors.ricevuta}</FormFeedback>
       </Col>   
       <Col>
          <Label for="datas">Data Scadenza</Label>
          <Input
            id="datas"
            type="date"
            value={values.datas}
            onChange={handleChange}
            onBlur={handleBlur}
            valid={!errors.datas && touched.datas}
            invalid={errors.datas && touched.datas}
           />
          <FormFeedback>{errors.datas}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
      <Col>  
        <Button disabled={isSubmitting} color="primary">
            Rinnova
        </Button>{' '}
        <Button onClick={handleReset} disabled={!dirty || isSubmitting} color="secondary">
            Cancella
        </Button>{' '}
      </Col>  
      </FormGroup>
    </Form>
    </Container>
   );
};

const NuovoPagamento = withFormik({
  mapPropsToValues: props => ({ ricevuta: '',datar:'',importo:'55', datas:'', idassociato: props.idassociato }),
  validationSchema: Yup.object().shape({
    datar: Yup.date()
      .min(MINRENEWALDAY,"La data rinnovo non può essere antecedente a più di 11 mesi fa")
      .max(new Date(),'La data rinnovo non può essere nel futuro')
      .required('Data rinnovo obbligatoria'),
    datas: Yup.date()
      .min(Yup.ref('datar') ? Yup.ref('datar') : new Date(), "La data scadenza deve essere superiore alla data di rinnovo"),
    importo: Yup.number()
      .required('Importo obbligatorio'),
  }),
  handleSubmit: (values, { setSubmitting, resetForm, setErrors }) => {
      setSubmitting(true);
      adalApiFetch(fetch, process.env.REACT_APP_BASE_SERVER_URI+'/inspagamento', {
          method: 'post',
          headers: {
              'Accept': 'application/json, application/xml, text/play, text/html, *.*',
              'Content-Type': 'application/json'
              },
          body: JSON.stringify(values),
      })
      .then(result=>{
          setSubmitting(false);
          if(result.status === 200){
            window.document.location.reload();
          }else{
            result.json()
            .then(body=>{
              resetForm();
              setErrors({fetch: body.error});
            })
          }
      });      
  },
  displayName: 'BasicForm', // helps with React DevTools
})(MyInnerForm);
export default NuovoPagamento;